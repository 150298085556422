<template>
  <div class="ekomi-custom-widget">
    <div
      id="widget-container"
      class="ekomi-widget-container ekomi-widget-sf1358626437d7bbc4ebc"
    ></div>

    <a
      href="https://www.ekomi.it/opinioni-sognidicristallo.html"
      target="_blank"
      ><img
        alt="SOGNI SRL Reviews with ekomi.it"
        src="https://smart-widget-assets.ekomiapps.de/resources/ekomi_logo.png"
        style="display: none"
    /></a>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'EkomiWidget',
});
</script>

<style lang="scss" scoped>
.ekomi-custom-widget {
  @include desktop-boxed;
  margin: 0 auto;
  padding: 0;
  border-top: var(--general-border);
}
</style>
